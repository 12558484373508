# Trix Extentions
# Add buttons for underline, mark, superscript, and subscript.
#
Trix.config.textAttributes.sup = { tagName: "sup", inheritable: true }
Trix.config.textAttributes.sub = { tagName: "sub", inheritable: true }

Trix.config.textAttributes.mark =
  tagName: 'mark'
  inheritable: true
Trix.config.textAttributes.underline =
  tagName: 'u'
  inheritable: true
  style: { 'textDecoration': 'underline' }

Trix.xcolor = {
  colors:
    'black': '#000000'
    'silver': '#C0C0C0'
    'grey': '#808080'
    'maroon': '#800000'
    'red': '#FF0000'
    'purple': '#800080'
    'green': '#008000'
    'olive': '#808000'
    'navy': '#000080'
    'blue': '#0000FF'
    'orange': '#FFA500'
    'yellow': '#ffff00'
    'white': '#FFFFFF'

  buttonHTML: """
    <button type="button" data-trix-action="x-color" class="trix-button trix-button--icon trix-button--icon-color" title="Color">Color</button>
  """

  dialogHTML: ->
    fg = []
    for color, hex of @colors
      fg.push """
        <label class="trix-x-color-label" data-x-set-color="#{hex}" data-x-set-color-attribute="foregroundColor" data-x-set-color-name="#{color}">
          <input type="radio" name="foregroundColor" value="#{hex}">
          <span class="trix-x-color-swatch" style="color: #{hex}" title="#{color}"><span class="sr-only">#{color}</span></span>
        </label>
      """

    bg = []
    for color, hex of @colors
      bg.push """
        <label class="trix-x-color-label" data-x-set-color="#{hex}" data-x-set-color-attribute="backgroundColor" data-x-set-color-name="#{color}">
          <input type="radio" name="backgroundColor" value="#{hex}">
          <span class="trix-x-color-swatch" style="background-color: #{hex}" title="#{color}"><span class="sr-only">#{color}</span></span>
        </label>
      """

    """<div class="trix-dialog trix-dialog--x-color" data-trix-dialog="x-color">
         <div class="trix-dialog--x-color-foreground">
          <h5>Text color</h5>
          #{fg.join(' ')}
         </div>
         <div class="trix-dialog--x-color-background">
           <h5>Background color</h5>
           #{bg.join(' ')}
         </div>
         <div class="trix-dialog--x-color-reset">
           <button type="button" name="x-color-reset" data-x-set-color="reset" class="btn btn-muted btn-sm btn-block">Remove all coloring</button>
         </div>
       </div>
    """

  apply: (editor, item)->
    color = item.data('x-set-color')
    if color == 'reset'
      editor.deactivateAttribute('foregroundColor')
      editor.deactivateAttribute('backgroundColor')
    else
      attribute = item.data('x-set-color-attribute')
      editor.activateAttribute(attribute, color)

    editor.composition.delegate.toolbarController.hideDialog()
}

getHexColor = (color) ->
  return "" unless color
  return color if /^#/.test(color)
  rgbValues = getRGBValues(color)
  hexValues = rgbValues.map(numberToHex)
  "#" + hexValues.join("")

numberToHex = (number) ->
  "0#{number.toString(16)}".slice(-2).toUpperCase()

getRGBValues = (color) ->
  if match = color.match(/rgba?\((\d+),\s*(\d+),\s*(\d+)/i)
    [..., r, g, b] = match
  else
    canvas = document.createElement("canvas")
    canvas.height = canvas.width = 1
    context = canvas.getContext("2d")
    context.fillStyle = color
    context.fillRect(0, 0, 1, 1)
    {data} = context.getImageData(0, 0, 1, 1)
    [r, g, b] = data
  [r, g, b].map(Number)

createColorParser = ->
  (element) ->
    while element and element.tagName isnt "TRIX-EDITOR"
      if styleColor = element.style[@styleProperty]
        if color = getHexColor(styleColor)
          return color
      element = element.parentElement

Trix.config.textAttributes.foregroundColor =
  inheritable: true
  styleProperty: "color"
  parser: createColorParser()

Trix.config.textAttributes.backgroundColor =
  inheritable: true
  styleProperty: "backgroundColor"
  parser: createColorParser()

addEventListener 'trix-initialize', (event)->
  element = event.target
  editor = element.editor
  toolbarElement = element.toolbarElement

  groupElement = toolbarElement.querySelector(".trix-button-group.trix-button-group--text-tools")
  groupElement.insertAdjacentHTML("beforeend", '<button type="button" data-trix-attribute="underline" class="trix-button trix-button--icon trix-button--icon-underline" title="Underline">Underline</button>')
  groupElement.insertAdjacentHTML("beforeend", '<button type="button" data-trix-attribute="mark" class="trix-button trix-button--icon trix-button--icon-mark" title="Highlight">Highlight</button>')
  groupElement.insertAdjacentHTML('beforeend', Trix.xcolor.buttonHTML)
  groupElement.insertAdjacentHTML("beforeend", '<button type="button" data-trix-attribute="sup" class="trix-button trix-button--icon trix-button--icon-sup" title="Superscript">Superscript</button>')
  groupElement.insertAdjacentHTML("beforeend",'<button type="button" data-trix-attribute="sub" class="trix-button trix-button--icon trix-button--icon-sub" title="Subscript">Subscript</button>')

  dialogsElement = toolbarElement.querySelector(".trix-dialogs")
  dialogsElement.insertAdjacentHTML('beforeend', Trix.xcolor.dialogHTML())

  $('[data-x-set-color]', dialogsElement).on 'click', (event)->
    Trix.xcolor.apply(editor, $(event.delegateTarget))

  selectedAttributes = new Set
  updateSelectedAttributes = ->
    selectedAttributes = new Set
    selectedRange = editor.getSelectedRange()
    selectedRange[1]++ if selectedRange[0] == selectedRange[1]

    selectedPieces = editor.getDocument().getDocumentAtRange(selectedRange).getPieces()
    selectedPieces.forEach((piece)->
      Object.keys(piece.getAttributes()).forEach((attribute)->
        selectedAttributes.add(attribute)
      )
    )

  enforceExclusiveAttributes = ->
    if editor.attributeIsActive("sup") && selectedAttributes.has("sub")
      editor.deactivateAttribute("sub")
      updateSelectedAttributes()

    else if editor.attributeIsActive("sub") && selectedAttributes.has("sup")
      editor.deactivateAttribute("sup")
      updateSelectedAttributes()

  updateSelectedAttributes()
  element.addEventListener("trix-selection-change", updateSelectedAttributes)
  element.addEventListener("trix-change", enforceExclusiveAttributes)

  # Add a paste handler to remove style attributes from pasted content.
  element.addEventListener 'trix-before-paste', (e)->
    return unless e.paste.hasOwnProperty('html')

    div = document.createElement('div')
    div.innerHTML = e.paste.html
    elementsWithStyle = div.querySelectorAll('[style]')
    for element in elementsWithStyle
      element.removeAttribute('style')
    e.paste.html = div.innerHTML
